<template>
<div class="items flex-col w-72 min-h-screen bg-white-500 shadow-t z-10">
        <template v-if="props.showHeaderIcon">
                <router-link  to="/" class="flex p-6 gap-2 items-center cursor-pointer">
                        <img src="../../../assets/Logo.png" class="h-6"  alt="Logo">
                        <h2 class="text-black-500 text-base font-extrabold">Magicter <span class="text-purple-500">Bro</span></h2>
                </router-link>
                
                <!-- <div class="flex text-gray-600">
                        <router-link class="text-sm w-full hover:bg-purple-100" :to="{ path: '/account' }" :class="{ 'border-l-4 border-purple-500 bg-purple-100': $route.path === '/account' }">
                                <div class="flex items-center gap-2 px-6 py-4">
                                        <HomeIcon class="h-5 w-5"/>
                                        <span>Home</span>
                                </div>
                        </router-link>
                </div> -->
        </template>
        <div class="flex items-center justify-between py-4 mr-4 text-gray-600">
                <p class="text-xs px-6  opacity-75 font-light">SERVICES</p>
                <XMarkIcon v-if="!props.showHeaderIcon" @click="handleSideBar" class="h-6 w-6 cursor-pointer"/>
        </div>
        <div class="flex flex-col text-gray-600">
                <router-link exact active-class="border-l-4 border-purple-500 bg-purple-100"  class="text-sm w-full hover:bg-purple-100" to="/account/top-up-center/new">
                        <div class="flex items-center gap-2 px-6 py-4">
                                <CreditCardIcon class="h-5 w-5"/>
                                <span>Top-up center</span>
                        </div>
                </router-link>
                <router-link exact active-class="border-l-4 border-purple-500 bg-purple-100"  class="text-sm w-full hover:bg-purple-100" to="/account/devices">
                        <div class="flex items-center gap-2 px-6 py-4">
                                <ServerStackIcon class="h-5 w-5"/>
                                <span>Device Management</span>
                        </div>
                </router-link>
                <!-- <router-link active-class="border-l-4 border-purple-500 bg-purple-100"  class="text-sm w-full hover:bg-purple-100" to="/account/planning-center">
                        <div class="flex items-center gap-2 px-6 py-4">
                                <CalendarDaysIcon class="h-5 w-5"/>
                                <span>Planning center</span>
                        </div>
                </router-link>
                <router-link active-class="border-l-4 border-purple-500 bg-purple-100"  class="text-sm w-full hover:bg-purple-100" to="/account/managment">
                        <div class="flex items-center gap-2 px-6 py-4">
                                <ClipboardDocumentIcon class="h-5 w-5"/>
                                <span>Managment board</span>
                        </div>
                </router-link>
                <router-link active-class="border-l-4 border-purple-500 bg-purple-100"  class="text-sm w-full hover:bg-purple-100" to="/account/marketing">
                        <div class="flex items-center gap-2 px-6 py-4">
                                <BanknotesIcon class="h-5 w-5"/>
                                <span>Marketing center</span>
                        </div>
                </router-link> -->
                <router-link active-class="border-l-4 border-purple-500 bg-purple-100"  class="text-sm w-full hover:bg-purple-100" to="/account/faq">
                        <div class="flex items-center gap-2 px-6 py-4">
                                <QuestionMarkCircleIcon class="h-5 w-5"/>
                                <span>FAQ</span>
                        </div>
                </router-link>
        </div>
        <div class="flex mt-auto p-4 text-sm">
                Expires:&nbsp;<strong>{{ expiryDateTime }}</strong>
        </div>
</div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
// import { HomeIcon, XMarkIcon, CreditCardIcon, ClipboardDocumentIcon, BanknotesIcon, CalendarDaysIcon, QuestionMarkCircleIcon, ServerStackIcon } from '@heroicons/vue/24/outline';
import { XMarkIcon, CreditCardIcon, QuestionMarkCircleIcon, ServerStackIcon } from '@heroicons/vue/24/outline';
import { useRouterStore } from '@/stores/router.state'
import { onMounted } from 'vue';
import { useAPI } from '@/composables/useAPI';

const { getExpires } = useAPI();


const props = defineProps({
        showHeaderIcon: {type: Boolean, default: true}
});

const handleSideBar = () => {
  const routeState = useRouterStore()
  routeState.handleSidebar();
}

function formatDateToCustomString(isoDateString) {
        const date = new Date(isoDateString);

        // 获取年份、月份、日期、小时和分钟
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // 格式化为 yyyy-MM-dd hh:mm
        return `${year}-${month}-${day} ${hours}:${minutes}`;
}

// 定义用于存储 expiry_date_time 的响应式数据属性
const expiryDateTime = ref('');

onMounted(async () => {
        const user = JSON.parse(localStorage.getItem('user'))
        const query = {
                userId: user.id,
                deviceId: user.device_id
        }
        const expires = await getExpires(query);
        const expiry_date_time = expires.data.expiry_date_time;

        // 更新响应式数据属性
        expiryDateTime.value = formatDateToCustomString(expiry_date_time);
        // console.log('user', expiry_date_time);
})

</script>
