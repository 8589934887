<template>

	<div class="flex h-screen w-screen justify-center items-center">
		<SpinnerLoader :full-loadder="true" />
	</div>

</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import SpinnerLoader from '@/molecules/SpinnerLoader.vue';
import { onMounted } from 'vue';
import { useConfig } from '@/composables/useConfig';
import { useAuth } from '@/composables/useAuth'; 

const { removeToken, removeStorage, setStorage, setToken } = useAuth();

const config = useConfig();

const router = useRouter();

const callAPI = async (method, url, data = null) => {
	try {
		const response = await fetch(config.API_URL + url, {
			method,
			body: data ? JSON.stringify(data) : null,
			headers: config.headers,
		});
		return await response.json();
	} finally {
		//   apiStore.isLoading = false;
	}
};

// 使用 useRoute 钩子获取当前路由信息
const route = useRoute();

// 获取查询参数 token 的值
const token = route.query.token;
const redirect = route.query.redirect;

function deleteToken() {
	if (token !== null || !token) {
		removeToken()
	}
}

async function login(){
	deleteToken()
	removeStorage('user')
	let res = await callAPI('POST', '/tokenlogin', { token: token })
	console.log('Result', res)
	if (res.statusCode === 200 && res.data) {
		console.log('Result', res)
		setToken(res.data.token);
		setStorage('user', JSON.stringify(res.data.data));
		router.push(redirect);
	}
}

onMounted(() => {
	login()
	
})

</script>